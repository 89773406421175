.App {
  text-align: center;
}

.markdown a {
  color: theme("colors.blue.300");
}

.markdown th {
  text-align: start;
  border: 1px solid theme("colors.slate.400");
  padding: 0.5rem;
}

.markdown table {
  border: 1px solid theme("colors.slate.400");
  border-collapse: collapse;
  border-radius: 2px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown table td {
  border: 1px solid theme("colors.slate.400");
  padding: 0.5rem;
}

.markdown ul {
  list-style-type: disc;
  margin-left: 1rem;
}

.markdown ol {
  list-style-type: decimal;
  margin-left: 1rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
}

input[type="text"], input[type="email"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

label {
  margin-right: 20px;
}

.flex.flex-col-reverse.grow.overflow-scroll {
  overflow-x: hidden;  /* Hide horizontal scrollbar */
  overflow-y: auto;    /* Show vertical scrollbar only if needed */
}


.icon-button {
  background: none;
  border: none;
  color: white; /* Blue color, you can choose any color you like */
  cursor: pointer;
  padding: 10px;
  font-size: 20px; /* Size of the icon */
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  color: rgba(255, 255, 255, 0.379); /* A darker blue for hover effect */
}

.app-container {
  display: flex;
  height: 100vh; /* Ensure the container takes full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Prevents scrolling at the app level */
}

.chat-container {
  padding: 15px;
  flex-grow: 1; /* Allows the chat container to take up the rest of the space */
  display: flex;
  flex-direction: column;
  /*  max-width: calc(100vw - 250px); Adjust width based on the sidebar */
  transition: max-width 0.3s ease; /* Smooth transition for resizing */
}

@media (max-width: 768px) {
  .sidebar-container.open + .chat-container {
      max-width: calc(100vw - 50px); /* Adjust for smaller sidebar on mobile */
  }

  .sidebar-container.closed + .chat-container {
      max-width: 100vw; /* Allow chat container to expand when sidebar is collapsed */
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-logo {
  animation: spin 2s linear infinite;
  width: 50px;
  height: 50px;
}
