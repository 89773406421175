.sidebar-container {
    width: 250px;
    height: 100vh;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    transition: width 0.3s ease;
    position: relative; /* Needed for absolute positioning of the toggle button */
}

.sidebar-container.closed {
    width: 50px; /* Smaller width when collapsed */
}

.hamburger {
    display: block;
    margin: 20px; /* Hide by default */
}

.toggle-button {
    position: absolute;
    top: 10px;
    right: -40px; /* Adjust based on your design preferences */
    background: #FF007A;
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.logo-section, .menu-items, .footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    max-width: 80%; /* Smaller logo when sidebar is collapsed */
    display: block; /* Ensure it's always visible */
}

.menu-items, .footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.3s ease;
}

.sidebar-container.closed .menu-items, 
.sidebar-container.closed .footer-section {
    opacity: 0; /* Hide text when collapsed */
    transition: opacity 0.1s ease-out 0.2s; /* Delayed fade out */
}

.menu-item {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 15px 20px;
    color: #C0C0C0; /* Light grey for menu items */
    text-decoration: none;
    font-size: 16px;
    white-space: nowrap; /* Prevents wrapping of menu items */
}

.menu-icon {
    margin-right: 10px;
}

.upgrade-btn {
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.menu-items .menu-item:hover,
.menu-items .menu-item.active {
    color: #00b7ff;
}

.footer-section {
    margin-top: 20px;
    text-align: center;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.modal-content {
    text-align: center;
}

.modal-content input {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
}

.modal-content button {
    margin-top: 10px;
    padding: 10px 20px;
    cursor: pointer;
}


.sidebar-container.closed {
    flex-direction: column;
}

@media (max-width: 768px) {
    .sidebar-container {
        width: 50px; /* Always collapsed on mobile */
    }
    .sidebar-container.open {
        width: 250px; /* Full width when opened manually */
    }
    .sidebar-container.closed {
        flex-direction: column;
    }
}

.sidebar-container.closed .hamburger {
    display: block; /* Show only when sidebar is closed */
}
label {
    float: left;
}

select { 
    margin: 0px !important;
}
