.scroll-down-button {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.scroll-down-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Responsive styles for mobile */
@media (max-width: 600px) {
    .scroll-down-button {
        bottom: 100px; /* Adjusted position for smaller screens */
        padding: 8px;
    }

    .scroll-down-button:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .scroll-down-button svg {
        width: 20px;
        height: 20px;
    }
}

/* Additional responsive styles for very small screens */
@media (max-width: 400px) {
    .scroll-down-button {
        bottom: 60px; /* Adjusted position for very small screens */
        padding: 6px;
    }

    .scroll-down-button svg {
        width: 16px;
        height: 16px;
    }
}