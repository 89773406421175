.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly darker background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* High z-index to ensure it's above other content */
}

.modal-content {
    background: #001f3f; /* Navy blue background */
    color: #ffffff; /* White text color */
    padding: 30px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 300px;
    text-align: center;
}

.login-form input {
    padding: 10px;
    margin-top: 10px;
    border: 2px solid #ffffff;  /* White border for better visibility */
    background-color: #ffffff;  /* White background for clarity */
    color: #333333;  /* Dark grey color for text, making it visible */
    border-radius: 5px;
}

.login-form input::placeholder {
    color: #777777;  /* Light grey for placeholders, ensuring visibility */
}

.login-form button {
    margin-top: 10px;
    padding: 10px;
    background-color: #007bff; /* Slightly lighter blue for the button */
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.login-form button:hover {
    background-color: #0056b3; /* Darker blue when hovered */
}

input[type="text"], input[type="email"] {
    display: block;
    width: 100%;           /* Makes the input take the full width of its container */
    padding: 10px;         /* Adds space inside the input for better readability */
    margin-top: 10px;      /* Adds space above the input for separation */
    border: 2px solid #ccc;/* Adds a light grey border */
    border-radius: 5px;    /* Rounds the corners of the input field */
    color: black;          /* Ensures the text color is black */
    background-color: #fff;/* Sets the background color to white for high contrast */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inner shadow for depth */
    font-size: 16px;       /* Sets a readable font size */
}

input[type="text"]::placeholder, input[type="email"]::placeholder {
    color: #999;           /* Light grey placeholder text for subtle hints */
}

input[type="text"]:focus, input[type="email"]:focus {
    border-color: #0056b3; /* Changes the border color to a darker blue on focus */
    box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.25); /* Adds a glow effect on focus */
    outline: none;         /* Removes the default focus outline */
}

button[type="submit"] {
    margin-top: 10px;
    background-color: green;
    padding: 10px;
}

button[type="submitgrey"] {
    margin-top: 10px;
    background-color: grey;
    padding: 10px;
}

button[type="cancel"] {
    margin-top: 10px;
    background-color: grey;
    padding: 10px;
}


select {
    margin-top: 10px;
    color: black;           /* Sets the text color inside the dropdown to black */
    background-color: white; /* Ensures the background is light, typically white */
    padding: 10px;          /* Adds padding for better text alignment and visibility */
    border: 1px solid #ccc; /* Adds a subtle border */
    border-radius: 5px;     /* Adds rounded corners */
    width: 100%;            /* Ensures it takes full width of its container */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Optional: adds an inset shadow for depth */
}

.chat-window {
    border: 1px solid #ccc;    /* Adds a border around the chat window */
    padding: 10px;             /* Adds padding inside the chat window */
    margin-top: 20px;          /* Adds some space above the chat window */
    height: 300px;             /* Sets a fixed height for the chat window */
    /*overflow-y: auto; */         /* Allows scrolling vertically if content exceeds the height */
    background-color: #f9f9f9; /* Sets a light background color */
    width: 100%;               /* Ensures it takes full width of its container */
}

.chat-message {
    background-color: #fff;    /* Sets a white background for individual messages */
    padding: 5px 10px;         /* Padding inside each message */
    margin-bottom: 5px;        /* Space between messages */
    border-radius: 5px;        /* Rounded corners for messages */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
}

.loading-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

p {
    color: #ffffff;
    /* font-size: larger; */
}

a .underline {
    text-decoration: underline;
}




